<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog_from_input"
      width="950"
      @click:outside="close()"
      @keydown.esc="close()"
      scrollable
    >

      <v-card
      tile
      >
        <v-card-title
          class="custom-modal-title"
          :style="backgroundColor"
          primary-title
        >
          {{edit_data ? 'Edit' : 'Create'}} Jobtitle
            <v-spacer></v-spacer>
          <span @click="close" style="cursor:pointer">x</span>
        </v-card-title>

        <v-card-text>
          <v-container>
               <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                    >
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="main_menu"
                          label="main_menu"
                          :rules="nameRules"
                          required
                          ></v-text-field>
                          <v-text-field
                          v-model="name"
                          label="Name"
                          :rules="nameRules"
                          required
                          ></v-text-field>
                          <v-text-field
                          v-model="path_url"
                          label="path_url"
                          :rules="nameRules"
                          required
                          ></v-text-field>
                          <v-text-field
                          v-model="note"
                          label="note"
                          :rules="nameRules"
                          required
                          ></v-text-field>
                      </v-col>
                    </v-row>
               </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            tile
            class="custom-modal-title"
            @click="close"
          >
            Cancel
          </v-btn>

           <v-btn
            color="success"
            tile
            :disabled="!valid"
            @click="edit_data ? update() : save()"
            :loading="loading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GlobalMixin from '../../../mixin/index'
export default {
  mixins:[GlobalMixin],
  methods: {
    send_data() {
      const data = new URLSearchParams()

      data.append('main_menu', this.main_menu)
      data.append('path_url', this.path_url)
      data.append('note', this.note)
      data.append('name', this.name)
      data.append('entry_by', this.user.fullname)
      data.append('company_id', this.user.company_id)
      return data
    },
    async save () {
      this.loading = true
      const data = this.send_data()
      await this.axios.post('v1/menu-access/create', data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success submit data',
            color_snakbar: 'success'
          })
          await this.go('menu-access','menu_access')
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },

    async update() {
      this.loading = true
      const data = this.send_data()
      await this.axios.put('v1/menu-access/update/' + this.edit_data.id, data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success update data',
            color_snakbar: 'success'
          })
          await this.go('menu-access','menu_access')
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },
    close () {
      this.reset()
      this.$emit('close')
    },
    reset () {
      this.users_role_id = ''
      this.name = ''
    },
    remove(data) {
      this[data] = ''
    },
    img(folder = null ,file = null) {
      if (file !== null) {
         return process.env.VUE_APP_IMGLINK + `${folder}/` + file
      } else {
        return this.imgurl
      }

    },
  },


  data: () => ({
    valid: true,
    lazy: false,
    loading: false,
    name: '',
    path_url: '',
    main_menu: '',
    note: '',

    nameRules: [
      v => !!v || 'Filed is required'
    ],
    imgurl: 'img/no_img.png',
    foto: null

  }),

  computed: {
    ...mapGetters({
      menu_access: 'data/menu_access',
    }),

  },
  async created () {
    if (this.edit_data) {
       this.name = this.edit_data.name
       this.path_url = this.edit_data.path_url
       this.main_menu = this.edit_data.main_menu
       this.note = this.edit_data.note
    }

  }
}
</script>
